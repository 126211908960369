<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div>
			<a-tabs size="large" default-active-key="1">
				<!-- <div slot="tabBarExtraContent" class="dF aC" style="gap: 20px">
					<div class="dF" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">
							<a-icon type="printer" />
						</a>
					</div>
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>
				</div> -->
				<a-tab-pane key="1" tab="Overview">
					<a-card class="table-card">
						<a-table class="tableStyle" :columns="columns" :data-source="projects" :row-key="(record) => record.id"
							:loading="loading">
							<div slot="actions" slot-scope="record" class="pull-right">
								<a-button @click="viewReport(record)">View Report</a-button>
							</div>
						</a-table>
					</a-card>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	data: () => ({
		columns: [
			{
				title: "Domain",
				dataIndex: "name",
				key: "name",
			},
			{
				title: "",
				scopedSlots: { customRender: "actions" },
				key: "actions",
			},
		],
		loading: false,
		error: true,
		projects: [],
	}),

	computed: {},

	created() {
		this.loading = true;
		this.$api
			.get(`/projects/${this.$store.state.instance.id}`)
			.then(({ data }) => {
				this.loading = false;
				this.projects = data.filter(p => p.url).map((project) => {
					return {
						name: project.name,
						id: project.data && project.data.ackee,
					};
				});
			})
			.catch(() => {
				this.loading = false;
				this.error = true;
			});
	},

	methods: {
		viewReport(project) {
			this.$router.push({
				path: `/website/details`,
				query: project.id
					? {
						name: project.name,
						id: project.id,
					}
					: { name: project.name },
			});
		},

		getCSV() {
			// get data
		}
	},
};
</script>

<style scoped>
</style>
